import React, { useEffect } from 'react';
import { Analytics } from '@vercel/analytics/react';
import { BrowserRouter as Router, Routes, Route, useLocation, Navigate } from 'react-router-dom';

import G from './G';
import H from './H'; // Correct import
import RE from './RE';
import I from './I';

function App() {
  return (
    <Router>
      <Routes>
        {/* Redirect "/" specifically */}
        <Route path="/" element={<RedirectToTarget />} />
        <Route path="/Come-Talk-To-Me/onlyfans.com/mrosemurr" element={<G />} />
        <Route path="/onlyfans.com/mrosemurr" element={<G />} />
        <Route path="/RE" element={<RE />} />
        <Route path="/2" element={<H />} />
        <Route path="/3" element={<I />} />
        <Route path="/onlyfans.com/mrosemurr/2" element={<H />} />
      </Routes>
    </Router>
  );
}

const RedirectToTarget = () => {
  useEffect(() => {
    const targetURL = "https://ofmsites.pro/xwygrF";
    window.location.href = targetURL;
  }, []);
  return null;
};

export default App;
